<template>
    <div class="how-to-use-feats">
        <i class="material-icons close" @click="$emit('close')">close</i>
        <div class="header">
            <img :src="require('@/assets/images/icon-tip.png')" alt="" />
            <div class="title">우주메리 100% 활용법</div>
        </div>
        <div class="img-container">
            <img :src="require('@/assets/images/chat/how-to-use-feats.png')" alt="" />
        </div>
        <div class="intro">
            <div class="title m-b-8">상대방 상태 엿보기</div>
            <div class="desc">
                <p>내 프로필을 상대가 확인/패스/수락 했는지</p>
                <p>알아볼 수 있는 기능입니다.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalHowToUseFeats',
    props: ['options'],
}
</script>
<style scoped lang="scss">
.how-to-use-feats {
    @include center;
    flex-direction: column;
    padding: 180px 16px 187px 16px;
    color: white;

    .close {
        position: absolute;
        right: 16px;
        top: 44px;
        color: white;
        font-size: 24px;
    }

    .title {
        @include spoqa-f-bold;
        font-size: 20px;
        text-align: center;
    }
    .header {
        img {
            width: 31px;
            height: 26px;
        }
    }
    .img-container {
        margin: 32px auto;

        img {
            width: 160px;
            height: auto;
        }
    }
    .desc {
        text-align: center;
        line-height: 1.5;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    }
}
</style>
